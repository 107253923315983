
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import { computed, defineComponent, getCurrentInstance, reactive, toRefs } from 'vue'
import { IResponse } from '@/common/http'

interface ITableRequest {
  name: string,
  type: string,
  required: boolean,
  description: string
}

interface ITableResponse {
  name: string,
  type: string,
  description: string
}

interface ITableData {
  name: string,
  label: string,
  package: boolean,
  domain: string,
  contentType: string,
  method: string,
  request: ITableRequest[],
  publicResponse: ITableResponse[],
  response: ITableResponse[]
}

interface IState {
  activeName: string,
  domain: string,
  tableData: ITableData[]
}

export default defineComponent({
  name: 'Api',
  setup () {
    const state = reactive<IState>({
      activeName: 'first',
      domain: '',
      tableData: [
        {
          name: 'first',
          label: '生成短链接',
          package: false,
          domain: '/ai/generate',
          contentType: 'application/json',
          method: 'POST',
          request: [
            {
              name: 'apikey',
              type: 'string',
              required: true,
              description: 'apikey'
            },
            {
              name: 'link',
              type: 'string',
              required: true,
              description: '需要缩短的链接'
            },
            {
              name: 'domain',
              type: 'string',
              required: false,
              description: '指定域名，不传则用默认域名'
            },
            {
              name: 'password',
              type: 'string',
              required: false,
              description: '访问密码，不传则不设置'
            },
            {
              name: 'day',
              type: 'int',
              required: false,
              description: '有效时间（天数），默认永久'
            },
            {
              name: 'name',
              type: 'string',
              required: false,
              description: '昵称备注'
            }
          ],
          publicResponse: [
            {
              name: 'code',
              type: 'int',
              description: '状态码 0为失败 1为成功'
            },
            {
              name: 'data',
              type: 'Array',
              description: '数据集'
            },
            {
              name: 'msg',
              type: 'string',
              description: '返回消息'
            },
            {
              name: 'time',
              type: 'string',
              description: '创建时间戳'
            }
          ],
          response: [
            {
              name: 'qrcode',
              type: 'string',
              description: 'Base64二维码'
            },
            {
              name: 'short',
              type: 'string',
              description: '短链接地址'
            }
          ]
        },
        {
          name: 'second',
          label: '修改链接域名',
          package: true,
          domain: '/ai/edit',
          contentType: 'application/json',
          method: 'POST',
          request: [
            {
              name: 'apikey',
              type: 'string',
              required: true,
              description: 'apikey'
            },
            {
              name: 'random',
              type: 'string',
              required: true,
              description: '随机码'
            },
            {
              name: 'link',
              type: 'string',
              required: true,
              description: '目标网址链接'
            }
          ],
          publicResponse: [
            {
              name: 'code',
              type: 'int',
              description: '状态码 0为失败 1为成功'
            },
            {
              name: 'data',
              type: 'Array',
              description: '数据集'
            },
            {
              name: 'msg',
              type: 'string',
              description: '返回消息'
            },
            {
              name: 'time',
              type: 'string',
              description: '创建时间戳'
            }
          ],
          response: []
        }
      ]
    })
    const { proxy }: any = getCurrentInstance()
    const stateAsRefs = toRefs(state)
    const store = useStore()
    const userInfo = computed(() => {
      return store.getters.GET_USER_INFO
    })

    const getDomain = (): void => {
      proxy.$axios.get('/config/apiDomain')
        .then((res: IResponse) => {
          if (res.code === 1) {
            state.domain = res.data
          }
        })
    }
    getDomain()

    const copy = (text: string): void => {
      const input = document.createElement('input')
      input.value = text
      document.body.appendChild(input)
      input.select()
      document.execCommand('Copy')
      document.body.removeChild(input)
      ElMessage.success({
        message: '已复制到粘贴板',
        type: 'success'
      })
    }

    return {
      ...stateAsRefs,
      userInfo,
      copy
    }
  }
})
